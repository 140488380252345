import ModelLoader from './ModelLoader/ModelLoader';
import ToggleSwitch from './ToggleSwitch/ToggleSwitch';
import './App.css';
import { useState } from 'react';

function App() {

  const [realTime, setRealTime] = useState(false);

  return (
    <div className="App">
      <h1 className='TitleText'>
        Tensorflow.js Letter Recognition
      </h1>
      <h2 className="InfoText">
        Draw a <strong>capital</strong> letter below and have the model classify it<br />
        This convolutional neural network (CNN) was trained on 26,000 images to identify capital handwritten characters
      </h2>

      <div className="realTime">
        <ToggleSwitch label="Real Time Identification" handleToggle={setRealTime} />
      </div>

      <ModelLoader realTime={realTime} />
      <div className="footer">
        Made by Cameron J
      </div>
    </div>
  );
}

export default App;
