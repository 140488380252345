import { useState, useEffect, useRef, useCallback } from 'react'
import styles from './DrawingCanvas.module.css';
import TensorflowImg from '../assets/Tensorflow.png';
import NextArrow from '../assets/Arrow.png';

function DrawingCanvas({ inputArray, setInputArray, realTime }) {
  const canvasRef = useRef(null);
  const inputCanvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [interacted, setInteracted] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [lastX, setLastX] = useState(0)
  const [lastY, setLastY] = useState(0);
  const [canvasScale, setCanvasScale] = useState(1);
  const [updateInterval, setUpdateInterval] = useState(null);

  useEffect(() => {
    if (!canvasRef || !canvasRef.current) return;
    let canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let dpi = window.devicePixelRatio;
    let style_height = +getComputedStyle(canvas).getPropertyValue("height").slice(0, -2);
    let style_width = +getComputedStyle(canvas).getPropertyValue("width").slice(0, -2);
    canvas.setAttribute('height', style_height * dpi);
    canvas.setAttribute('width', style_width * dpi);
    let canvasScale2 = (style_width * dpi) / 300;
    ctx.scale(canvasScale2, canvasScale2);
    setCanvasScale(canvasScale2);
  }, [canvasRef]);

  const updateInput = useCallback(() => {
    console.log("update");
    let input = [];

    const canvas = inputCanvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, 28, 28);
    ctx.drawImage(canvasRef.current, 0, 0, 28, 28);

    const imageData = ctx.getImageData(0, 0, 28, 28);
    const pixels = imageData.data;

    for (let x = 0; x < 28; x++) {
      let row = []
      for (let y = 0; y < 28 * 4; y += 4) {
        const red = pixels[x * 28 * 4 + y + 1];
        const green = pixels[x * 28 * 4 + y + 1];
        const blue = pixels[x * 28 * 4 + y + 2];
        const alpha = pixels[x * 28 * 4 + y + 3];

        row.push(alpha > 125 ? [red, green, blue] : [255, 255, 255]);
      }
      input.push(row);
    }
    setInputArray(input);
  }, [inputCanvasRef, canvasRef, setInputArray]);
  const updateInputRef = useRef(updateInput);

  useEffect(() => {
    if (!inputCanvasRef || !inputCanvasRef.current) return;
    let canvas = inputCanvasRef.current;
    canvas.setAttribute('height', 28);
    canvas.setAttribute('width', 28);
  }, [inputCanvasRef]);

  useEffect(() => {
    if (!inputCanvasRef || !inputCanvasRef.current) return;
    if (!canvasRef || !canvasRef.current) return;
    if (!interacted) return;
    if (!drawing && !realTime) {
      updateInput();
    }
  }, [drawing, inputCanvasRef, canvasRef, interacted, updateInput, realTime]);

  const clearCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, 300, 300);
    if (!realTime) updateInput();
  }, [canvasRef, updateInput, realTime]);

  useEffect(() => {
    if (realTime) {
      const intervalEvent = window.setInterval(updateInputRef.current, 500);
      setUpdateInterval(intervalEvent);
    }
    else if (updateInterval) {
      window.clearInterval(updateInterval);
    }
    // eslint-disable-next-line
  }, [realTime, updateInputRef]);

  return (
    <div className={styles.DrawingCanvas}>
      <div
        className={`${styles.CanvasCover} ${hovered ? 'noBG' : ''}`}
        onMouseDown={() => { setHovered(true) }}
      >
        <div className={`${styles.CoverText} ${hovered ? 'hidden' : ''}`}>
          <div>
            <strong>Draw Here</strong>
            <br />
            Try to center the letter and
            <br />
            cover most of the canvas
            <p>Only <span className={styles.important}><u><b>CAPITAL</b></u></span> letters work!</p>
          </div>
        </div>
        <canvas
          className={styles.canvas}
          ref={canvasRef}
          onMouseDown={
            (e) => {
              if (!canvasRef || !inputCanvasRef || !canvasRef.current || !inputCanvasRef.current) return;
              setDrawing(true);
              const canvas = canvasRef.current;
              var rect = canvas.getBoundingClientRect();
              let mouseX = (e.clientX - rect.left) / (rect.right - rect.left) * canvas.width / canvasScale;
              let mouseY = (e.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height / canvasScale;
              setLastX(mouseX);
              setLastY(mouseY);
            }
          }
          onMouseMove={
            (e) => {
              if (!drawing) return
              if (!interacted) setInteracted(true);
              const canvas = canvasRef.current;
              const ctx = canvas.getContext('2d');

              var rect = canvas.getBoundingClientRect();
              let mouseX = (e.clientX - rect.left) / (rect.right - rect.left) * canvas.width / canvasScale;
              let mouseY = (e.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height / canvasScale;

              ctx.lineWidth = 15;
              ctx.lineCap = 'round'
              ctx.strokeStyle = "black";
              ctx.beginPath()
              ctx.moveTo(lastX, lastY)
              ctx.lineTo(mouseX, mouseY)
              ctx.stroke()
              setLastX(mouseX)
              setLastY(mouseY)
              ctx.closePath()
            }
          }
          onMouseUp={
            () => setDrawing(false)
          }
          onMouseLeave={
            () => setDrawing(false)
          }
        />
      </div>
      <img className={styles.Arrow} src={NextArrow} alt="Tensorflow Logo" />
      <div className={styles.Section2}>
        <button className="hidden">Clear</button>
        <canvas ref={inputCanvasRef} className={styles.canvasSmall} />
        <button onClick={() => clearCanvas()}>Clear</button>
      </div>
      <img className={styles.Arrow} src={NextArrow} alt="Tensorflow Logo" />
      <img className={styles.TFLogo} src={TensorflowImg} alt="Tensorflow Logo" />
    </div>
  )
}


export default DrawingCanvas