/*
Credit: https://www.geeksforgeeks.org/how-to-create-a-toggle-switch-in-react-as-a-reusable-component/
*/

import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ label, handleToggle }) => {

  function handleChange(e) {
    const newVal = e.target.checked;
    handleToggle(newVal);
  }

  return (
    <div className="container">
      <u>{label}</u>{" "}
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox"
          name={label} id={label} onChange={handleChange} />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;