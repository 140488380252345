import React, { useState, useEffect } from 'react';
import styles from './OutputChart.module.css'
import { BarChart, Bar, Rectangle, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

function OutputChart({ probabilities, appeared }) {

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!probabilities) return;
    setData(Object.entries(probabilities).map(([letter, probability]) => ({ name: letter, "Probability": probability })));
  }, [probabilities]);

  return (
    <div className={`${styles.OutputChart} ${appeared ? '' : 'hidden'}`}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 10,
            right: 8,
            left: 8,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="name"
            tickLine={false}
            style={{
              fontFamily: 'handWritten',
            }} />
          <YAxis
            tickCount={5}
            domain={[0, 1]}
            style={{
              fontFamily: 'handWritten',
              fontSize: "14px"
            }}
          />
          <Tooltip />
          <Bar dataKey="Probability" fill="#82ca9d" activeBar={<Rectangle fill="gold" />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default OutputChart;