import styles from './ModelLoader.module.css';
import * as tf from '@tensorflow/tfjs';
import OutputChart from '../OutputChart/OutputChart';
import DrawingCanvas from '../DrawingCanvas/DrawingCanvas';
import { useState, useEffect } from 'react';

function ModelLoader({ realTime }) {

    const [model, setModel] = useState(null);
    const [modelInput, setModelInput] = useState(null);
    const [inputArray, setInputArray] = useState(null);
    const [outputArray, setOutputArray] = useState(null);
    const [showPredictions, setShowPredictions] = useState(false);

    useEffect(() => {
        (async () => {
            let model2 = await tf.loadLayersModel('model.json');
            if (model2 && model2.predict) {
                model2.compile({
                    loss: 'categoricalCrossentropy',
                    optimizer: 'adam',
                    metrics: ['accuracy']
                });
                setModel(model2);
            }
        })();
    }, []);

    useEffect(() => {
        if (!model) return;
        if (!inputArray) return;
        setModelInput(inputArray);
        setShowPredictions(true);
    }, [inputArray, model]);

    const getPredictionValue = async (tensor) => {
        var values = await tensor.data();
        let parsedOutputs = [];
        for (let i = 0; i < values.length; i++) {
            parsedOutputs.push(Number(values[i]))
        }
        parsedOutputs = parsedOutputs.map(output => Math.round(output * 100) / 100);
        const outputs = {}
        const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        for (let i = 0; i < alpha.length; i++) {
            outputs[alpha[i]] = parsedOutputs[i];
        }
        setOutputArray(outputs);
    }

    useEffect(() => {
        if (model && modelInput && modelInput.length > 0) {
            let inputArray = [modelInput]
            var value = model.predict(tf.tensor(inputArray));
            getPredictionValue(value);
        }
    }, [model, modelInput]);

    return (
        <div className={styles.ModelLoader}>
            <DrawingCanvas inputArray={inputArray} setInputArray={setInputArray} realTime={realTime} />
            <OutputChart probabilities={outputArray} appeared={showPredictions} />
        </div>
    );
}

export default ModelLoader;
